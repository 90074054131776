import { cx } from 'class-variance-authority'
import { formatInTimeZone } from 'date-fns-tz'
import { useTranslation } from 'react-i18next'

import { useRootContext } from '~/utils/hooks/use-root-context'

export const CopyrightPrivacyPolicy: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const { timezone } = useRootContext()

  return (
    <ul className={cx('flex justify-center divide-x border-gray-200 text-center text-xs', className)}>
      <li className='pr-2'>
        <p>
          © {t('common.indigov')}, {formatInTimeZone(new Date(), timezone.name, 'yyyy')}
        </p>
      </li>
      <li className='pl-2'>
        <a
          className='text-primary-800 hover:text-primary-800 hover:underline'
          target='_blank'
          href='https://indigov.com/pages/privacy-policy'
          rel='noreferrer'
        >
          {t('common.privacyPolicy')}
        </a>
      </li>
    </ul>
  )
}
